<template>
  <ContentHeader/>
  <div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <div class="card">
               <div class="card-body profile-page p-0">
                  <div class="profile-header profile-info">
                     <div class="cover-container">
                        <img :src="page_banner" alt="profile-bg" class="rounded img-fluid" style="width:100%;">
                        <input class="banner-upload" type="file" accept="image/*" v-on:change="edit_banner" name="banner" ref="banner" style="display:none;"/>
                        <ul class="header-nav d-flex flex-wrap justify-end p-0 m-0" style="z-index:999;">
                           <li v-if="isMyPage">
                              <a v-if="!new_banner" @click="$refs.banner.click()" id="profile_banner_edit">
                                 <i class="ri-pencil-line"></i>
                              </a>
                              <a v-if="new_banner" @click="upload_banner">
                                 <i class="ri-upload-cloud-line"></i>
                              </a>
                           </li>
                           <li v-if="isMyPage">
                              <router-link to="/profile/edit">
                                 <i class="ri-settings-4-line"></i>
                              </router-link>
                           </li>
                        </ul>
                     </div>
                     <div class="user-detail text-center mb-3">
                        <div class="profile-img">
                           <img :src="'https://tshaku.com/'+page_avatar" alt="profile-img" class="avatar-130 img-fluid" />
                        </div>
                        <div class="profile-detail">
                           <h3 class="">{{page_title}}</h3>
                        </div>
                     </div>
                     <div class="profile-info p-4 d-flex align-items-center justify-content-between position-relative">
                        <div class="social-links">
                           <ul class="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                              <li class="text-center pe-3">
                                 <a href=""><img src="https://tshaku.com/view/socialV/assets/images/icon/08.png" class="img-fluid rounded" alt="facebook"></a>
                              </li>
                              <li class="text-center pe-3">
                                 <a href=""><img src="https://tshaku.com/view/socialV/assets/images/icon/09.png" class="img-fluid rounded" alt="Twitter"></a>
                              </li>
                              <li class="text-center pe-3">
                                 <a href=""><img src="https://tshaku.com/view/socialV/assets/images/icon/10.png" class="img-fluid rounded" alt="Instagram"></a>
                              </li>
                              <li class="text-center pe-3">
                                 <a href=""><img src="https://tshaku.com/view/socialV/assets/images/icon/11.png" class="img-fluid rounded" alt="Google plus"></a>
                              </li>
                              <li class="text-center pe-3">
                                 <a href=""><img src="https://tshaku.com/view/socialV/assets/images/icon/12.png" class="img-fluid rounded" alt="You tube"></a>
                              </li>
                              <li class="text-center pe-3">
                                 <a href=""><img src="https://tshaku.com/view/socialV/assets/images/icon/13.png" class="img-fluid rounded" alt="linkedin"></a>
                              </li>
                           </ul>
                        </div>
                        <div class="social-info">
                           <ul class="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                              <li class="text-center pe-3">
                                 <h6>Posts</h6>
                                 <p class="mb-0">
                                    <button>{{page_posts}}</button>
                                 </p>
                              </li>
                              <li class="text-center pe-3">
                                 <h6>Following</h6>
                                 <p class="mb-0">
                                    <button>{{page_following}}</button>
                                 </p>
                              </li>
                              <li class="text-center pe-3">
                                 <h6>{{page_followers}} Followers</h6>
                                 <p class="mb-0">                                                                        
                                    <button v-if="is_a_follower" type="submit" class="btn btn-primary" @click="unfollow_page()">Un-Follow</button>
                                    <button v-else type="submit" class="btn btn-primary" @click="follow_page()">Follow</button>
                                 </p>
                              </li>                              
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>      
      <div class="row">
         <SocialPoster :avatar="avatar"/>         
         <input type="hidden" name="page_id" :id="page_id" value=""/>         
      </div>                     
      <div class="row">
         <div class="col-lg-4">
                                                
         </div>
         <div class="col-lg-4">
                                                
         </div>
         <div class="col-lg-4">
                        
         </div>
      </div>
         <div class="row">            
            <div class="col-lg-9">                        
               <div id="all-wall-posts">            
                  <div v-for="post in posts" :key="post.post_id">
                     <Posts :post_comments="post.post_comments" :post_date="post.post_date" :post_user_sub="post.post_user_sub" :post_text="post.post_text" :post_shares="post.post_shares" :post_link="post.post_link" :post_likes="post.post_likes" :post_page_id="post.post_page_id" :post_image="post.post_image" :post_by_avatar="post.post_by_avatar" :post_by_id="post.post_by_id" :post_by_names="post.post_by_names" :post_id="post.post_id"  :post_type="post.post_type" />
                  </div>
               </div>     
               <div id="spin_loader"><center><img src="https://tshaku.com/view/socialV/assets/images/page-img/page-load-loader.gif"/></center></div>
               <input type="hidden" id="active_post" value="0"/>        
               <input type="hidden" id="last_loaded_post" value="0"/>           
            </div>
            <div class="col-lg-3">
               <div class="card">
                  <div class="card-header d-flex justify-content-between">
                     <div class="header-title">
                        <h4 class="card-title">About</h4>
                     </div>
                  </div>
                  <div class="card-body">
                     <ul class="list-inline p-0 m-0">
                        <li class="mb-2 d-flex align-items-center">
                           <i class="ri-user-line pe-3 font-size-20"></i>
                           <p class="mb-0">{{page_title}}</p>
                        </li>
                        <li class="mb-2 d-flex align-items-center">
                           <i class="ri-map-pin-line pe-3 font-size-20"></i>
                           <p class="mb-0">{{page_location}}</p>
                        </li>
                        <li class="mb-2 d-flex align-items-center">
                           <i class="ri-git-repository-line pe-3 font-size-20"></i>
                           <p class="mb-0">{{page_desc}}</p>
                        </li>
                        <li class="d-flex align-items-center">
                           <i class="ri-heart-line pe-3 font-size-20"></i>
                           <p class="mb-0">{{page_relationship}}</p>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="card">
                  <div class="card-header d-flex justify-content-between">
                     <div class="header-title">
                        <h4 class="card-title">Photos</h4>
                     </div>
                     <div class="card-header-toolbar d-flex align-items-center">                        
                     </div>
                  </div>
                  <div class="card-body">
                     <ul class="profile-img-gallary p-0 m-0 list-unstyled" id="app_photos_area">
                           <li class="" v-for="photo in photos" :key="photo.id">
                              <a href="#">
                                 <img :src="photo.url" alt="gal19lary-image" class="img-fluid">
                              </a>
                           </li>                           
                     </ul> 
                  </div>
               </div>
               <div class="card">
                  <div class="card-header d-flex justify-content-between">
                     <div class="header-title">
                        <h4 class="card-title">Followers</h4>
                     </div>
                     <div class="card-header-toolbar d-flex align-items-center">

                     </div>
                  </div>
                  <div class="card-body">
                     <ul class="profile-img-gallary p-0 m-0 list-unstyled">
                         <li class="" v-for="follower in followers" :key="follower.profile_id">
                              <router-link :to="'/profile/'+follower.profile_id">
                                 <img :src="'https://tshaku.com/'+follower.avatar" alt="gallary-image" class="img-fluid">
                              </router-link>
                              <h6 class="mt-2 text-center">{{follower.names}}</h6>
                           </li>                           
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>   
  <ContentFooter />
</template>

<script>
import {computed, reactive, ref} from 'vue'
import { useHead } from '@vueuse/head'
import axios from 'axios'
import ContentHeader from './includes/ContentHeader.vue'
import ContentFooter from './includes/ContentFooter.vue'
import SocialPoster from './includes/SocialPoster.vue'
import Posts from './includes/Post.vue'



export default {
    name : 'Profile',
    setup(props){        
        const siteData = reactive({
        title: 'Tshaku - ',
        description : 'Description',
        })
        useHead({
        //Can be static or computed
        title: computed(() => siteData.title),
        meta: [
            {
            name:'description',
            content: computed(() => siteData.description)
            }
        ],      
        })
    },     
    components:{ContentHeader,ContentFooter,SocialPoster,Posts},
    data(){
      return{
         user_id:'',
         first_name:'',
         last_name:'',                     
         avatar:'https://tshaku.com/',
         page_id:null,
         page_title:null,
         page_avatar:null,         
         page_location:null,
         page_banner:null,
         new_banner:false,
         new_page_banner:null,
         page_desc:null,
         page_fb:null,
         page_twitter:null,
         page_insta:null,
         page_google:null,
         page_youtube:null,
         page_linkedin:null,
         page_following:null,
         page_occupation:null,
         page_relationship:null,
         page_inshuti_id:null,
         page_posts:0,
         page_followers:0,
         posts:null,
         photos:null,
         followers:null,
         seen_posts:null,
         is_a_follower:false,
         org_id:37         
      }
    },
    props:['id'],
    watch: { 
        id: function() { // watch it
            this.page_info()
            this.scrollToTop()
        }
    },
    async created(){      
         document.body.classList.add('dark')  
         this.page_info()
        //*/
    },   
    computed:{
      isMyPage(){   
        var token=localStorage.getItem('token');
        //alert(token)
        if(!token){
         return false;
        }
        else{
         //Currently logged in user id
         var this_user= this.$store.state.userId
         if(this.page_inshuti_id==this_user){            
            return true
         }
         else{
            return false
         }         
        }                                             
      }
    },
    methods: {          
         edit_banner(e){
            const image = e.target.files[0]
            this.new_page_banner = image
            this.page_banner = URL.createObjectURL(image)
            this.new_banner=true
         },
         async upload_banner(){
            var form = new FormData();            
            form.append('action','edit_page')
            form.append('do','banner')
            form.append('org_id',37)
            form.append('banner',this.new_page_banner)
            let conf = {
               header : {
                  'Content-Type' : 'multipart/form-data'
               }
            }                      
            const response = await axios.post('api.php',form,conf)
            //Let do some validations here
            //End let do some validations here
            if(response.status==200){
               //There are no communication issue with the endpoint
               const data=response.data
               if(data.response_code==200){
                  //The request has worked, redirect user to the login page
                  alert('Banner succesfully updated')                        
               }
               else{                  
                  alert('data not sent')
               }
            }
            else{
               alert('An error occured during data submission, please contact support')
            }
         },
         scrollToTop(){
            window.scrollTo({ top: 0, behavior: 'smooth' });
         },         
         async page_info(){
            const response = await axios.get('api.php?action=get_page_info&user_id='+this.id+'&org_id=37',{
                  headers: {
                     Authorization:localStorage.getItem('token')
                  }
            })        
            var data=response.data   
            //console.log(data)      
            //*
            if(data.response_code==200){  
               this.page_id=data.page_id
               this.page_title=data.page_title
               this.page_avatar=data.page_avatar
               this.page_location=data.page_location
               this.page_banner='https://tshaku.com/'+data.page_banner
               this.page_desc=data.page_desc
               this.page_fb=data.page_fb
               this.page_twitter=data.page_twitter
               this.page_insta=data.page_insta
               this.page_google=data.page_google
               this.page_youtube=data.page_youtube
               this.page_linkedin=data.page_linkedin
               this.page_following=data.page_following
               this.page_occupation=data.page_occupation
               this.page_relationship=data.page_relationship
               this.page_inshuti_id=data.page_inshuti_id
               this.page_posts=data.page_posts
               this.page_followers=data.page_followers
               this.page_type=data.page_type
               this.page_org_id=data.page_org_id
               this.is_a_follower=data.is_a_follower
               /* Set up meta tags here */
               useHead({ title:"Tshaku - "+this.page_title+' profile page'})
               /* End Set up tags here */
               //Load the page's post                                           
               this.load_photos()
               this.load_followers()
               //Check if the user is logged in
               this.load_posts()               
               //window.document.title = this.page_title+' is on tshaku.com - The Future of Online Social Networking'
            }
            else{
                  
            }            
         },
         async load_posts(){            
            //*
            const posts = await axios.get('post.php?action=load&user_id='+this.id,{
                headers: {
                    Authorization:localStorage.getItem('token')
                }
            })                    
            this.posts=posts.data   
            //console.log(posts.data)
            this.scrollToTop()            
        },
        async load_photos(){
            const photos = await axios.get('photo.php?action=get&inshuti_id='+this.id+'&org_id=37',{
                headers: {
                    Authorization:localStorage.getItem('token')
                }
            })                    
            this.photos=photos.data               
        },                        
        async load_followers(){
            const followers = await axios.get('pages.php?action=followers&page_id='+this.page_id+'&org_id=37',{
                headers: {
                    Authorization:localStorage.getItem('token')
                }
            })                    
            this.followers=followers.data            
        },
         async follow_page(){
            ///*
            var url='pages.php?action=follow&page_id='+this.page_id            
            const response = await axios.get(url,{
                headers: {
                    Authorization:localStorage.getItem('token')
                }
            })
            var data=response.data                                 
            if(data.response_code==200){
                this.is_a_follower=true;
                alert('You are now following '+this.page_title)
            }
            else{
                alert('Unable to follow this user, please try again later')
            } 
            //*/           
            //Send the axios         
        },
        async unfollow_page(){
            var url='pages.php?action=unfollow&page_id='+this.page_id
            const response = await axios.get(url,{
                headers: {
                    Authorization:localStorage.getItem('token')
                }
            })
            var data=response.data                                 
            if(data.response_code==200){
                this.is_a_follower=false;
                alert('You have unfollowed '+this.page_title)
            }
            else{
                alert('Unable to un-follow this user, please try again later')
            }            
        },                
    }    
}
</script>

<style>
      .row-container {display: flex; width: 100%; height: 100%; flex-direction: column; background-color: blue; overflow: hidden;}
      .second-row { flex-grow: 1; border: none; margin: 0; padding: 0; }

</style>