<template>  
    <router-view @updateavatar="updateavatar"/>  
</template>

<script>
import {computed, reactive, ref} from 'vue'
import axios from 'axios'
import router from '@/router'
import { useHead } from '@vueuse/head'

export default {   
  setup(){
    const siteData = reactive({
      title: 'Tshaku is the future of online social networking',
      description : 'The future of online social networking',
    })
    useHead({
      //Can be static or computed
      title:siteData.title,
      meta: [
        {
          name:'description',
          content: siteData.description
        }
      ],      
    })
  }, 
  data(){
    return{
      user_id:'',
      first_name:'',
      last_name:'',
      avatar:'https://tshaku.com/'
    }
  },
  methods:{
    updateavatar(newvalue){
      this.$store.dispatch("updateAvatar",newvalue)
      //this.avatar='https://tshaku.com/'+newvalue
    }
  },
  async created(){ 
        var token=localStorage.getItem('token');
        //alert(token)
        if(!token){
            //There is no token the user is logged out              
            if(this.$route.name!='login' && this.$route.name!='register' && this.$route.name!='profile'){
              //this.$router.push('/login')                      
            }
        }
        else{
          const response = await axios.get('api.php?action=get_inshuti',{
              headers: {
                  Authorization:localStorage.getItem('token')
              }
          })        
          var data=response.data         
          //*
          if(data.response_code==200){            
              //The user is still signed in                        
              /*
              this.user_id=data.user_id
              this.first_name=data.first_name
              this.last_name=data.last_name
              this.avatar+=data.avatar
              */
              this.$store.dispatch("addUserInfo",data)
              if(this.$route.name=='login' || this.$route.name=='register'){
                //the user is signed in redirect them to the homepage
                this.$router.push('/')
              }
              //Load user posts           
          }        
          else{
              //Access denied remove the cookie and redirect to the login page            
              localStorage.removeItem('token')
              this.$router.push('/login')
          }        
        }
        //*/
        document.body.classList.add('dark')
  },
  mounted(){    
    
  }          
}
</script>  
<style>
  body{
    /*color: #8c91b6;*/
    background-color: #050505;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    /*color: #2c3e50;*/
  }
  .user-post{height:auto;}      
  #content-page{
    padding:0;
  }  
  .video {
      aspect-ratio: 16 / 9;
      width: 100%;
  }
  .row-container {display: flex; width: 100%; height: 100%; flex-direction: column; background-color: blue; overflow: hidden;}
  .second-row { flex-grow: 1; border: none; margin: 0; padding: 0; }
  .post_zone_div img{max-height:100%;}
  a:hover{cursor:pointer}
  .video-wrap {
    text-align: center;
  }
  .video-inner {  
    iframe {
      max-width: 100%;
      max-height: 100%;
    }
    &.stuck {
      z-index:99999;
      position: fixed;
      bottom: 145px;
      left: 0px;
      transform: translateY(100%);
      width: 250px;
      height: 245px;
      animation: fade-in-up .25s ease forwards;
    }
  }

</style>
