<template>
  <section class="sign-in-page">
        <div id="container-inside">
            <div id="circle-small"></div>
            <div id="circle-medium"></div>
            <div id="circle-large"></div>
            <div id="circle-xlarge"></div>
            <div id="circle-xxlarge"></div>
        </div>
        <div class="container p-0">
            <div class="row">
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item"><router-link to="/" class="nav-link"><b style="color:#FFF;">Home</b></router-link></li>
                            <li class="nav-item"><router-link to="/login" class="nav-link"><b style="color:#FFF;">Login</b></router-link></li>
                        </ul>                    
                    </div>
                </nav>
            </div>
            <form class="mt-4">	
                <div class="row no-gutters">
                    <div class="col-md-6 bg-white">
                        <div class="form-group">
                            <div>
                                <label class="form-label"  for="f_name">*First Name:</label> &nbsp;  &nbsp; <input class="form-control mb-0" type="text" v-model="f_name" value="" id="f_name"/><br/>
                            </div>
                            <div>
                                <label class="form-label"  for="l_name">*Last Name:</label> &nbsp;  &nbsp; <input class="form-control mb-0" type="text" v-model="l_name" value="" id="l_name"/><br/>				
                            </div>
                            <!--
                            <div>
                            <label class="form-label"  for="age" <?php if(isset($age_error) AND $age_error==true){echo ' style="color:red;"';}?>>*Age:</label>   &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; &nbsp;<input class="form-control mb-0" type="text" name="age" value="<?php if(isset($_POST['age'])){echo htmlspecialchars($_POST['age']);}?>" id="age"/><br/>
                            </div>
                            
                            <div>
                            <label class="form-label"  for="picture" <?php if(isset($error_avatar) AND $error_avatar==true){echo ' style="color:red;"';}?>>Your Picture:</label> &nbsp; <input class="form-control mb-0" type="file" name="avatar" /><br/>
                            </div>
                            -->
                        </div>
                        <div class="form-group">
                            <div>
                                <label class="form-label"  for="email" >*Email:</label><input class="form-control mb-0" type="text" v-model="email" value="" id="email"/>
                            </div>
                            <div>
                                <label class="form-label"  for="password">*New Password:</label><input class="form-control mb-0" type="password" v-model="password" id="password" value=""/>
                            </div>
                            <div>				
                                <label class="form-label"  for="re_password">*Confirm Password:</label><input class="form-control mb-0" type="password" v-model="re_password" id="re_password" value=""/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 bg-white pb-lg-0 pb-5">
                        <div class="form-group">
                            <div>
                                <label class="form-label"  for="phone" >*Phone Number :</label><input class="form-control mb-0" type="text" v-model="phone_number" id="phone_number" value=""/>
                            </div>
                            <div>
                                <label class="form-label"  for="account_pin" >*Account PIN :</label><input class="form-control mb-0" type="text" v-model="account_pin" maxlength="4" id="account_pin" value=""/>
                            </div>
                        </div>					
                        <!--
                        <div class="row"  style="border:1px solid #0363bc; color:#0363bc;">
                            <legend>What Gender are you?</legend>
                            <div>
                            <label class="form-label"  for="gender_male">Male</label></td><td><input class="form-control mb-0" type="radio" name="gender" value="Male" id="gender_male" checked="checked"/>
                            </div>
                            <div>
                            <label class="form-label"  for="gender_female">Female</label><input class="form-control mb-0" type="radio" name="gender" value="Female" id="gender_female"/>
                            </div>
                        </div>
                        -->
                        <div class="form-group">
                            <div>
                                <label class="form-label"  for="country">*Country</label>
                                <select v-model="country" id="country">
                                <option value="United States">United States</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">American Samoa</option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antarctica">Antarctica</option>
                                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                <option value="Botswana">Botswana</option>
                                <option value="Bouvet Island">Bouvet Island</option>
                                <option value="Brazil">Brazil</option>
                                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                <option value="Brunei Darussalam">Brunei Darussalam</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">Burkina Faso</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Cape Verde">Cape Verde</option>
                                <option value="Cayman Islands">Cayman Islands</option>
                                <option value="Central African Republic">Central African Republic</option>
                                <option value="Chad">Chad</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Christmas Island">Christmas Island</option>
                                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Congo">Congo</option>
                                <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                <option value="Cook Islands">Cook Islands</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Cote D'ivoire">Cote D'ivoire</option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">Czech Republic</option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">Dominican Republic</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                <option value="Faroe Islands">Faroe Islands</option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="French Guiana">French Guiana</option>
                                <option value="French Polynesia">French Polynesia</option>
                                <option value="French Southern Territories">French Southern Territories</option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guinea-bissau">Guinea-bissau</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                <option value="Korea, Republic of">Korea, Republic of</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macao">Macao</option>
                                <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">Marshall Islands</option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                <option value="Moldova, Republic of">Moldova, Republic of</option>
                                <option value="Monaco" Monaco>Monaco</option>
                                <option value="Mongolia" Mongolia>Mongolia</option>
                                <option value="Montserrat" Montserrat>Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="Namibia">Namibia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="Netherlands Antilles">Netherlands Antilles</option>
                                <option value="New Caledonia">New Caledonia</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk Island">Norfolk Island</option>
                                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau">Palau</option>
                                <option value="Palestinian Territory">Palestinian Territory</option>
                                <option value="Panama" Panama>Panama</option>
                                <option value="Papua New Guinea" Papua New Guinea>Papua New Guinea</option>
                                <option value="Paraguay" Paraguay>Paraguay</option>
                                <option value="Peru" Peru>Peru</option>
                                <option value="Philippines" Philippines>Philippines</option>
                                <option value="Pitcairn" Pitcairn>Pitcairn</option>
                                <option value="Poland" Poland>Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Reunion">Reunion</option>
                                <option value="Romania">Romania</option>
                                <option value="Russian Federation" Russian Federation>Russian Federation</option>
                                <option value="Rwanda" selected="selected">Rwanda</option>
                                <option value="Saint Helena" Saint Helena>Saint Helena</option>
                                <option value="Saint Kitts and Nevis" Saint Kitts and Nevis>Saint Kitts and Nevis</option>
                                <option value="Saint Lucia" Saint Lucia>Saint Lucia</option>
                                <option value="Saint Pierre and Miquelon" Saint Pierre and Miquelon>Saint Pierre and Miquelon</option>
                                <option value="Saint Vincent and The Grenadines" Saint Vincent and The Grenadines>Saint Vincent and The Grenadines</option>
                                <option value="Samoa">Samoa</option>
                                <option value="San Marino">San Marino</option>
                                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                <option value="Saudi Arabia">Saudi Arabia</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Serbia and Montenegro">Serbia and Montenegro</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">Sierra Leone</option>
                                <option value="Singapore" Singapore>Singapore</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">Solomon Islands</option>
                                <option value="Somalia">Somalia</option>
                                <option value="South Africa">South Africa</option>
                                <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                <option value="Swaziland" Swaziland>Swaziland</option>
                                <option value="Sweden" Sweden>Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Timor-leste">Timor-leste</option>
                                <option value="Togo">Togo</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">Turkmenistan</option>
                                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab Emirates">United Arab Emirates</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="United States">United States</option>
                                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Viet Nam">Viet Nam</option>
                                <option value="Virgin Islands, British">Virgin Islands, British</option>
                                <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                <option value="Wallis and Futuna">Wallis and Futuna</option>
                                <option value="Western Sahara">Western Sahara</option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                            </div>
                            <div>
                            <label class="form-label"  for="city">*City</label><input class="form-control mb-0" type="text" v-model="city" id="city" value=""/>
                            </div>
                            <div>				
                            <label class="form-label"  for="address">*Address</label><input class="form-control mb-0" type="text" v-model="address" id="address" value=""/>
                            </div>
                        </div>
                        <!--
                        <div class="form-group">
                            <legend>Describe yourself a little</legend>
                            <div>
                            <label class="form-label"  for="bio" <?php if(isset($error_bio) AND $error_bio==true){echo ' style="color:red;"';}?>>*short bio:</label>
                            </div>
                            <div>
                            <textarea cols="25" rows="3" name="bio" id="bio"><?php if(isset($_POST['bio'])){ echo htmlspecialchars($_POST['bio']);}?></textarea>					
                            </div>					
                        </div>
                        -->
                        <div style="text-align:center;border:none;text-align:center;width:100%;">
                            <div class="g-recaptcha" data-sitekey="6LehakQUAAAAAP5pF9TahYw_JpO1ZSclwXBjkttl"></div>
                        </div>
                        <div class="row"  style="text-align:center;border:none;">
                            <table>
                                <tr>
                                    <td>
                                        <input type="checkbox" v-model="news_and_updates" id="news_and_updates" style="width:20px; display:block;" checked="checked"/>
                                    </td>
                                    <td style="font-size:11px;font-weight:bold;">
                                        I would like to receive news, updates , promotions and offers from this site
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="row"  style="text-align:center;border:none;">
                            <table>
                                <tr>
                                    <td style="">
                                        <input type="checkbox" v-model="tos" id="tos" style="width:20px;display:block;" />
                                    </td>
                                    <td style="font-size:11px;font-weight:bold;">
                                        I aggree to all <a href="tos.php" target="_blank">terms of services</a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row"  style="border:none; background:#FFF;">
                    <div class="col-md-12">
                        <button class="form-control mb-0 sbmt_bttn submit" type="submit" @click.prevent="handleRegistration()">create my account</button>
                    </div>
                </div>			
            </form>
        </div>
    </section>

</template>

<script>
import axios from 'axios'
import {computed, reactive, ref} from 'vue'
import { useHead } from '@vueuse/head'
export default {
    name: 'Register',
    setup(props){        
        const siteData = reactive({
        title: 'Tshaku - Register - New User Welcome',
        description : 'Tshaku registration page',
        })
        useHead({
        //Can be static or computed
        title: computed(() => siteData.title),
        meta: [
            {
            name:'description',
            content: computed(() => siteData.description)
            }
        ],      
        })
    },             
    data(){
        return{
            f_name:'',
            l_name:'',
            email:'',
            password:'',
            re_password:'',
            phone_number:'',
            account_pin:'',
            country:'',
            city:'',
            address:'',
            news_and_updates:'',
            wallet_currency:'RWF',
            org_id:37,
            tos:false,
            action:'userRegister',
            avatar:''
        }
    },    
    methods: {
        async handleRegistration(e){    
            //validate form fields
            if(!this.f_name || !this.l_name || !this.email || !this.password || !this.re_password || !this.phone_number ||
             !this.account_pin || !this.country || !this.city || !this.address){
                alert('Please fill in all mendatory fields')
             }
             else{
                //Check if the user agreed to our terms and services                                
                if(!this.tos){
                    alert('Please agree to our terms of services')
                    
                }
                else{
                    //Check if email is valid javascript
                    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                    if(!this.email.match(validRegex)){
                        alert('Please input a valid email address')
                    }
                    else{
                        //Check if password and password confirm are the same
                        if(this.password!=this.re_password){
                            //The password and password confirmation do not match
                            alert('Password and Passowrd confirmation do not match')
                        }
                        else{
                            //Let submit the data to the server
                            var form = new FormData();
                            form.append('f_name',this.f_name)
                            form.append('l_name',this.l_name)
                            form.append('email',this.email)
                            form.append('phone_number',this.phone_number)
                            form.append('password',this.password)
                            form.append('account_pin',this.account_pin)
                            form.append('country',this.country)
                            form.append('city',this.city)
                            form.append('address',this.address)
                            form.append('news_and_updates',this.news_and_updates)
                            form.append('tos',this.tos)
                            form.append('org_id',this.org_id)
                            form.append('wallet_currency',this.wallet_currency)
                            form.append('action',this.action)
                            form.append('avatar',this.avatar)                            
                            const response = await axios.post('api.php',form)
                            //Let do some validations here
                            //End let do some validations here
                            if(response.status==200){
                                //There are no communication issue with the endpoint
                                const data=response.data
                                if(data.response_code==200){
                                    //The request has worked, redirect user to the login page
                                    this.$router.push('/login');
                                }
                                else{
                                    //console.log(data);
                                    alert(data.response_desc)
                                }
                            }
                            else{
                                alert('An error occured during data submission, please contact support')
                            }   
                        }
                    }                    
                }
             }            
        }
    }
}
</script>

<style>

</style>