import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createHead } from '@vueuse/head'
const head = createHead()

import axios from './axios'

import './assets/css/libs.min.css'
import './assets/css/socialv.css?v=4.0.0'
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
import './assets/vendor/remixicon/fonts/remixicon.css'
import './assets/vendor/vanillajs-datepicker/dist/css/datepicker.min.css'
import './assets/vendor/line-awesome/dist/line-awesome/css/line-awesome.min.css'


createApp(App).use(head).use(store).use(router).mount('#app')
